import React, { useEffect, useState } from 'react';
import './App.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IconContext } from "react-icons";
import { FaRegCircleQuestion } from "react-icons/fa6";

import 'bootstrap/dist/css/bootstrap.min.css';

import useFontFaceObserver from 'use-font-face-observer';
import FitText from '@kennethormandy/react-fittext'

import $ from 'jquery';
import _ from 'lodash';

var timeout = false;

const pokeballs = [{
        image: "/poke-ball.png",
        hexColor: "#ffd8b1"
    },
    {
        image: "/net-ball.png",
        hexColor: "#a0dbe1"
    },
    {
        image: "/dive-ball.png",
        hexColor: "#b3e7f7"
    },
    {
        image: "/dive-ball.png",
        hexColor: "#b3e7f7"
    },
    {
        image: "/dusk-ball.png",
        hexColor: "#c9eab7"
    },
    {
        image: "/premier-ball.png",
        hexColor: "#e8e1f1"
    },
    {
        image: "/quick-ball.png",
        hexColor: "#b0d9f2"
    },
    {
        image: "/great-ball.png",
        hexColor: "#a4cfee"
    },
    {
        image: "/heal-ball.png",
        hexColor: "#f4a6c1"
    },
    {
        image: "/repeat-ball.png",
        hexColor: "#fff9c4"
    },
    {
        image: "/luxury-ball.png",
        hexColor: "#f2d28b"
    },
    {
        image: "/timer-ball.png",
        hexColor: "#f7b0a2"
    },
    {
        image: "/master-ball.png",
        hexColor: "#f4a3cc"
    },
    {
        image: "/ultra-ball.png",
        hexColor: "#fef4cc"
    },
    {
        image: "/net-ball.png",
        hexColor: "#c4e5a3"
    },


];

const ballChoice = _.sample(pokeballs);


function App(props) {
    const [pokemon, setPokemon] = useState(null);

    const [start, setStart] = useState(Date.now());
    const [arrivalTime, setArrivalTime] = useState(86400);
    const [millis, setMillis] = useState(Date.now() - start);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const webFontsLoaded = useFontFaceObserver([{ family: `Pokemon Solid` }]);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [time, setTime] = useState("00:00:00");

    function processPokemonName(pokemonName) {
        // Check if the name ends with '-m' and replace it with 'male'
        if (pokemonName.endsWith('-m')) {
            return pokemonName.slice(0, -2) + ' Male';
        }
        // Check if the name ends with '-f' and replace it with 'female'
        else if (pokemonName.endsWith('-f')) {
            return pokemonName.slice(0, -2) + ' Female';
        }
        // Return the name as is if it doesn't end with '-m' or '-f'
        return pokemonName;
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    var toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }

    function resizeImageAndQuote() {
        function hasOverflow() {
            /*            let totalChildrenHeight = 0;
                        element.children().each(function() {
                            totalChildrenHeight += $(this).height();
                        });*/
            return $("#mainContainer").height() - $("#calcContainer").height();
        }
        const mapNumRange = (num, inMin, inMax, outMin, outMax) =>
            ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
        let percentage = 100;
        let tries = 0;
        while (Math.abs(hasOverflow()) > 2 && tries < 1000) {
            if (hasOverflow() > 0) {
                console.log("Content++");
                percentage += mapNumRange(Math.abs(hasOverflow()), 0, $(window).height() / 2, 0.01, 10);
            }
            if (hasOverflow() < 0) {
                console.log("Content--");
                percentage -= mapNumRange(Math.abs(hasOverflow()), 0, $(window).height() / 2, 0.01, 10);
            }

            let newImageHeight = mapNumRange(percentage, 0, 100, 225, 475);
            $("#pokemonImage").height(newImageHeight);
            $("#pokemonImage").width(newImageHeight);
            if (Math.abs(hasOverflow()) > 50) {
                let newH1Size = mapNumRange(percentage, 0, 100, 30, 50);
                $("h1").css("fontSize", newH1Size);
                let newQuoteSize = mapNumRange(percentage, 0, 100, 9, 36);
                $("#quoteText").css("fontSize", newQuoteSize);
            }
            tries++;
        }
        console.log("Tries: " + tries);
        // while (!hasOverflow($div)) {
        //     let currentImageHeight = $("#pokemonImage").height();
        //     let newImageHeight = currentImageHeight + (currentImageHeight / 100);
        //     $("#pokemonImage").height(newImageHeight)

        //     let currentQuoteSize = $("#quoteText").css("fontSize").slice(0, -2);;
        //     console.log(currentQuoteSize);
        //     let newQuoteSize = currentQuoteSize + (currentQuoteSize / 100);
        //     $("#quoteText").css("fontSize", newQuoteSize);
        // }
    }

    useEffect(() => {
        console.log("Get pokemon data")
        // Set document title
        document.title = "Poke Quote - Daily Pokemon Wisdom"
        // Fetch data from your FastAPI backend
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.pokequote.com/'); // Adjust URL as needed
                const data = await response.json();
                setArrivalTime(data.next_quote_arrival);
                data.name = capitalizeFirstLetter(processPokemonName(data.name));
                setPokemon(data);
                setTimeout(() => {
                    resizeImageAndQuote();
                }, 100);

                setTimeout(() => {
                    $("#loaderContainer").animate({
                        opacity: 0.0,
                    }, 800, function() {
                        $("#loaderContainer").hide();

                    });
                    $(".pokeLoader").animate({
                        height: 400,
                        width: 400
                    }, 1000);
                    //$("#loaderContainer").hide();
                }, 1000);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {

        /*while (overflowing) {
            console.log(overflowing);
            $("#pokemonImage").height($("#pokemonImage").height() + 10)
        }*/

        // while (!overflowing) {
        //     console.log("Growing image")
        // }
        /*const quoteContainerHeight = $(window).height() - ($("#topRow").height() + $("#bottomRow").height() + $("#imageRow").height());
        $("#quoteRow").height(quoteContainerHeight);*/
    }, []);

    useEffect(() => {
        const handleResize = () => {
            resizeImageAndQuote();
            /*const quoteContainerHeight = $(window).height() - ($("#topRow").height() + $("#bottomRow").height() + $("#imageRow").height());
$("#quoteRow").height(quoteContainerHeight);
forceUpdate();*/
        };
        window.addEventListener('resize', function() {
            clearTimeout(timeout);
            timeout = setTimeout(resizeImageAndQuote, 250);
        });
    }, []);

    useEffect(() => {
        setMillis(Date.now() - start);
        setTime(toHHMMSS(arrivalTime - Math.floor(millis / 1000)));
        const interval = setInterval(() => {
            setMillis(Date.now() - start);
            setTime(toHHMMSS(arrivalTime - Math.floor(millis / 1000)));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    })


    return (
            <div className="App">
            <Modal show={show} onHide={handleClose} {...props} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <h4>Poke Quote - Daily Pokemon Wisdom</h4>
                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body >
                    <b>Welcome to Poke Quote your daily dose of Pokemon wisdom!</b> Every day, we feature a new Pokemon along with a unique, inspirational quote generated by advanced AI technology. 
                    Dive into the world of Pokemon and discover a fresh, motivational message each day that captures the essence and spirit of different Pokémon. 
                    Whether you're a lifelong fan or new to the Pokemon universe, Poke Quote offers a fun and inspiring way to connect with these beloved creatures. 
                    Tune in daily to see which Pokemon takes the spotlight and what words of wisdom it has to offer. 
                    Start your day with a spark of creativity and inspiration, Pokemon style!
                    <hr />
                    <b>Explore Our API!</b><br/>
                    For developers interested in utilizing a Poke Quote in their own applications, a free to use API is available.
                    Learn how to access daily quotes, implement API calls, and integrate our content into your applications. 
                    Our API is accessible at <a href="https://api.pokequote.com/">api.pokequote.com</a>. For documentation on how to use the Poke Quote API go to <a href="https://api.pokequote.com/docs">api.pokequote.com/docs</a>.
                </Modal.Body> 
                <Modal.Footer >
                    <a href="#">Creativity by Algorithmic Alchemy</a>
                </Modal.Footer > 
            </Modal>
            <div id="loaderContainer" className="backgroundDiv position-absolute z-1" style={{ backgroundColor: ballChoice?.hexColor }}>
                <img className="pokeLoader" src={ballChoice.image} />
                <p className="textLoader">Loading</p>
            </div>
            <div className="backgroundDiv z-0" style={{ backgroundColor: pokemon?.background_hex }}>
                <div id="mainContainer" className="container">
                    <div id="calcContainer">
                        <div id="topRow" className="d-flex flex-row mx-3">
                            <div className="flex-grow-1 my-3">
                                <h1 className="title" style={{ color: pokemon?.background_hex }}>#{pokemon?.id} {pokemon?.name}</h1>
                            </div>
                            <div className="my-3 me-3">
                                
                            </div>
                            <div className="my-3">
                                <IconContext.Provider value={{ color: "black", className: "info", size: "2em"}}>
                                    <Button variant="link" onClick={handleShow}>
                                        <FaRegCircleQuestion />
                                    </Button>
                                </IconContext.Provider>
                            </div>
                        </div>
                        <div id="imageRow" className="d-flex flex-row justify-content-center">
                            <div className="">
                                <img id="pokemonImage" className="ratio ratio-1x1" src={pokemon?.image_url} alt={pokemon?.name} />
                            </div>
                        </div>
                        <div id="quoteRow" className="d-flex flex-row justify-content-center">
                            <div className="d-flex">
                                <p id="quoteText" className="h-auto pb-5">{pokemon?.quote}</p>
                            </div>
                        </div>
                        <div id="bottomRow" className="d-flex flex-row mx-3 align-items-end justify-content-end">
                            <div className="flex-grow-1">
                                <p className="nextText">New quote arriving in {time}</p>
                            </div>
                            <div className="">
                                <h1 className="logo py-3" style={{ color: pokemon?.background_hex }}>Poke Quote</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <
        /div>
    );
}

export default App;